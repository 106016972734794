import React from 'react';
import { func, string, number, array } from 'prop-types';
import classNames from 'classnames/bind';
import Select from 'react-select';

import styles from './ChildrenAgeSelector.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  index: number.isRequired,
  childAgeLabel: string.isRequired,
  handleСhildrenAgeChange: func.isRequired,
  childrenAges: array.isRequired,
  maxChildrenAge: number.isRequired,
  minChildrenAge: number.isRequired,
};

const ChildrenAgeSelector = ({
  index: i,
  childAgeLabel,
  handleСhildrenAgeChange,
  childrenAges,
  maxChildrenAge,
  minChildrenAge,
  className,
}) => {
  return (
    <div className={cx('children-age-fieldset', className)}>
      <div className={cx('children-age-label')}>
        {childAgeLabel} {i + 1}
      </div>
      <Select
        onChange={({ value }) => handleСhildrenAgeChange(i, value)}
        value={{
          value: childrenAges[i],
          label: childrenAges[i],
        }}
        options={[...Array(maxChildrenAge - minChildrenAge + 1)]
          .map((_, i) => minChildrenAge + i)
          .map((age) => ({
            value: age,
            label: age,
          }))}
        className={cx('select', 'children-age-select')}
        classNamePrefix="select"
      />
    </div>
  );
};

ChildrenAgeSelector.propTypes = propTypes;

export default ChildrenAgeSelector;
