import React from 'react';
import Head from 'react-helmet';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import ContentLoader from 'common/components/ContentLoader';

import GTM from '~/components/GTM';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import FormSignUpOneclick from '~/scenes/FormSignUpOneclick';
import {
  FORM_MESSAGE_STATE,
  FORM_PASSWORD_ONECLICK_STATE,
  FORM_LOADING_STATE,
} from '~/constants';
import FormMessage from '~/components/MyidComponents/FormMessage';

import SignUpHandler from './containers/SignUpHandler';
import SetPasswordOneClickHandler from './containers/SetPasswordOneClickHandler';
import styles from './SignUp.module.scss';

const cx = classNames.bind(styles);

const SignUp = () => {
  const { t } = useTranslation('auth');

  // I18n data
  const pageTitle = t('create.pageTitle');
  const infoLabels = t('create.messages', {
    returnObjects: true,
  });

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <div className={cx('root')}>
        <SignUpHandler>
          {({ isEmailDisabled, formState, formMessages, signUpData }) => (
            <div className={cx('form-wrapper')}>
              {formState === FORM_PASSWORD_ONECLICK_STATE && (
                <SetPasswordOneClickHandler>
                  {({ setPasswordOneClickHandler }) => (
                    <FormSignUpOneclick
                      submit={setPasswordOneClickHandler}
                      signUpData={signUpData}
                      isEmailDisabled={isEmailDisabled}
                      oneClickSetPassword
                    />
                  )}
                </SetPasswordOneClickHandler>
              )}
              {formState === FORM_MESSAGE_STATE && (
                <FormMessage messages={formMessages} labels={infoLabels} />
              )}
              {formState === FORM_LOADING_STATE && (
                <div className={cx('form-loader')}>
                  <ContentLoader />
                </div>
              )}
            </div>
          )}
        </SignUpHandler>
      </div>

      <GTM />
    </>
  );
};

export default withErrorBoundary(SignUp);
