import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import IconContainer from 'common/components/IconContainer';
import HighlightedText from 'common/components/HighlightedText';

import FullscreenContainer, {
  FullscreenContentWrapper,
} from '~/components/FullscreenContainer';
import Header from '~/scenes/Header';

import { ReactComponent as Gear } from '~/media/icons/gear.icon.svg';

import styles from './Maintenance.module.scss';

const cx = classNames.bind(styles);

const Maintenance = () => {
  const { t } = useTranslation('common');

  return (
    <FullscreenContainer>
      <Header />
      <FullscreenContentWrapper>
        <div className={cx('root')}>
          <div className={cx('content')}>
            <div className={cx('image')}>
              <div className={cx('icons-container')}>
                <IconContainer
                  icon={Gear}
                  className={cx('icon-gear', 'gear-1')}
                />
                <IconContainer
                  icon={Gear}
                  className={cx('icon-gear', 'gear-2')}
                />
                <IconContainer
                  icon={Gear}
                  className={cx('icon-gear', 'gear-3')}
                />
              </div>
            </div>
            <div className={cx('text-container')}>
              <div className={cx('title')}>
                <HighlightedText index={t('maintenanceModeTitleHighlight')}>
                  {t('maintenanceModeTitle')}
                </HighlightedText>
              </div>
              <div
                className={cx('text')}
                dangerouslySetInnerHTML={{ __html: t('maintenanceModeText') }}
              />
            </div>
          </div>
        </div>
      </FullscreenContentWrapper>
    </FullscreenContainer>
  );
};

export default Maintenance;
