import React from 'react';
import { object, func, bool } from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import Input from 'common/components/FormComponents/Input';
import InputPassword from 'common/components/FormComponents/InputPassword';
import { useEffectOnMount } from 'common/hooks';
import gtmPushEvent from 'common/utils/gtmPushEvent';
import AuthFormTitle from 'common/components/AuthFormTitle';

import FormButton from '~/components/MyidComponents/FormButton';
import ServiceLinks from '~/components/MyidComponents/ServiceLinks';

import FormSignUpOneclickHandler from './containers/FormSignUpOneclickHandler';
import styles from './FormSignUpOneclick.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  submit: func.isRequired,
  signUpData: object.isRequired,
  isEmailDisabled: bool,
  oneClickSetPassword: bool,
};

const defaultProps = {
  isEmailDisabled: false,
  oneClickSetPassword: false,
};

const gtmSignupStep2Params = {
  event: 'load_account_registration_flow_step2',
};

function FormSignUpOneclick({
  submit,
  isEmailDisabled,
  oneClickSetPassword,
  signUpData,
}) {
  useEffectOnMount(() => {
    gtmPushEvent(gtmSignupStep2Params);
  });

  const { t } = useTranslation('auth');
  const title = t('create.oneclickPasswordFormTitle');
  const ctaLabel = t('create.formTitle.value');
  const termsAndConditionsOneclickText = t(
    'create.termsAndConditionsOneclickText'
  );
  const loginLabel = t('create.fieldLoginLabel');
  const passwordLabel = t('create.fieldPasswordLabel');
  const serviceLinks = t('create.serviceLinks');

  return (
    <FormSignUpOneclickHandler
      data={signUpData}
      oneClickSetPassword={oneClickSetPassword}
    >
      {({ validationSchema, initialValues }) => (
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values, formik) => {
            submit(values, formik);
          }}
        >
          {({ values, errors, isSubmitting }) => {
            return (
              <Form className={cx('form')} autoComplete="off" noValidate>
                <AuthFormTitle title={title} />

                <div className={cx('form-content')}>
                  <Field
                    name="login"
                    component={Input}
                    theme="block-theme"
                    placeholder={loginLabel}
                    required
                    maxLength={128}
                    className={cx('input')}
                    autoComplete="username"
                    disabled={isEmailDisabled}
                  />
                  <Field
                    name="password"
                    theme="block-theme"
                    component={InputPassword}
                    placeholder={passwordLabel}
                    required
                    maxLength={128}
                    className={cx('input')}
                    autoComplete="off"
                  />
                  <div
                    className={cx('text')}
                    dangerouslySetInnerHTML={{
                      __html: termsAndConditionsOneclickText,
                    }}
                  ></div>
                  <FormButton
                    isSubmitting={isSubmitting}
                    disabled={
                      Object.entries(values).some((item) =>
                        item.includes('')
                      ) ||
                      Object.entries(errors).length > 0 ||
                      isSubmitting
                    }
                    buttonLabel={ctaLabel}
                  />
                  <ServiceLinks data={serviceLinks} />
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </FormSignUpOneclickHandler>
  );
}

FormSignUpOneclick.propTypes = propTypes;
FormSignUpOneclick.defaultProps = defaultProps;

export default FormSignUpOneclick;
