import React from 'react';
import classNames from 'classnames/bind';

import styles from './FullscreenContainer.module.scss';

const cx = classNames.bind(styles);

const FullscreenContentWrapper = ({ children }) => {
  return (
    <div className={cx('wrapper-root')}>
      <div className={cx('content-wrapper')}>{children}</div>
    </div>
  );
};

const FullscreenContainer = ({ children }) => {
  return <main className={cx('root')}>{children}</main>;
};

export { FullscreenContentWrapper };
export default FullscreenContainer;
