import i18n from 'i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import XHRBackend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';

import { MYACC_LANGUAGE_WHITELIST } from 'common/constants';

import { CLIENT_APP_VERSION } from '~/constants';

const { REACT_APP_MYACC_ENV } = process.env;

export default function initI18n(lng) {
  if (i18n.isInitialized) return;

  const initialLng = lng?.slice(0, 2) || 'en';
  const storageLng = localStorage.getItem('i18nextLng')?.slice(0, 2);

  i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      lng: initialLng,
      fallbackLng: 'en',
      debug: false,
      whitelist: MYACC_LANGUAGE_WHITELIST,
      nonExplicitWhitelist: true,
      load: 'languageOnly',
      ns: ['auth'],
      defaultNS: 'auth',
      backend: {
        backends: [
          LocalStorageBackend, // primary
          XHRBackend, // fallback
        ],
        backendOptions: [
          {
            prefix: 'i18next_res_',
            expirationTime: 15 * 60 * 1000, // @TODO: change to 7 * 24 * 60 * 60 * 1000 after dynamic version will be implemented https://github.com/i18next/i18next-localstorage-backend/issues/20 , so we will organize endpoint with last translation version.
            defaultVersion: CLIENT_APP_VERSION, // @TODO: should be a random hash, that generated after editing json configuratior on backoffice
          },
          {
            crossDomain: true,
            loadPath: (() => {
              switch (REACT_APP_MYACC_ENV) {
                case 'local':
                  return '/locales/{{lng}}/{{ns}}.json?bump27022024';
                case 'dev':
                  return 'https://my-dev.montecarlosbm.com/translations/{{lng}}/{{ns}}.json?bump27022024';
                case 'stage':
                  return 'https://my-stage.montecarlosbm.com/translations/{{lng}}/{{ns}}.json?bump27022024';
                default:
                  return 'https://my.montecarlosbm.com/translations/{{lng}}/{{ns}}.json?bump27022024';
              }
            })(),
          },
        ],
      },
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    });

  moment.locale(initialLng || storageLng || 'en');
}
