import React, { useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { parse } from 'query-string';

import HighlightedText from 'common/components/HighlightedText';

import { sbmClient } from '~/apolloClient';
import { useMyaccHistory } from '~/hooks';

import styles from './MyIDTopBanner.module.scss';

const cx = classNames.bind(styles);

const MyIDOfferDataQuery = gql`
  query MyIDOfferData($id: ID!) {
    offer(id: $id) {
      title
      subtitle
      loginBackgroundTitle
      loginBackgroundSubtitle
    }
  }
`;

const MyIDTopBanner = () => {
  const { t } = useTranslation('login-slider');
  const args = {
    returnObjects: true,
  };
  const slides = [
    t('login-slider:slide1', args),
    t('login-slider:slide2', args),
    t('login-slider:slide3', args),
  ].filter((slide) => slide.enabled);

  const slickSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    cssEase: 'linear',
  };

  const {
    location: { search },
  } = useMyaccHistory();

  const { offerID } = parse(search);

  const [getOfferData, { data: myIDOfferData }] = useLazyQuery(
    MyIDOfferDataQuery,
    {
      variables: {
        id: offerID,
      },
      client: sbmClient,
    }
  );

  useEffect(() => {
    if (offerID) {
      getOfferData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerID]);

  const myIDOffer = myIDOfferData?.offer;

  const offerTitle = myIDOffer?.loginBackgroundTitle || myIDOffer?.title;

  const offerSubtitle =
    myIDOffer?.loginBackgroundSubtitle || myIDOffer?.subtitle;

  if (!slides.length && !myIDOffer) {
    return null;
  }

  return (
    <section className={cx('root')}>
      <div className={cx('container')}>
        {myIDOffer ? (
          <div className="notification-content">
            <p className="notification-text">{offerTitle}</p>
            <p className="notification-details">{offerSubtitle}</p>
          </div>
        ) : (
          <Slider {...slickSettings}>
            {slides.map((slide, i) => {
              return (
                <div className="notification-content" key={i}>
                  {slide.title && slide.title.value && (
                    <p className="notification-text">
                      <HighlightedText
                        index={slide.title.color_position}
                        color={slide.color}
                      >
                        {slide.title.value}
                      </HighlightedText>
                    </p>
                  )}
                  {slide.subtitle && (
                    <p className="notification-details">{slide.subtitle}</p>
                  )}
                </div>
              );
            })}
          </Slider>
        )}
      </div>
    </section>
  );
};

export default MyIDTopBanner;
