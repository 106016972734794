import React, { useMemo } from 'react';
import { array, object, string, bool } from 'prop-types';
import classNames from 'classnames/bind';

import HighlightedText from 'common/components/HighlightedText';

import styles from './BookingCalculator.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  dates: array.isRequired,
  stayData: object.isRequired,
  nightFromLabel: string.isRequired,
  yourStayLabel: string.isRequired,
  yourPriceLabel: string.isRequired,
  nightSingleLabel: string.isRequired,
  nightPluralLabel: string.isRequired,
  className: string,
  isMobile: bool,
  sumOnly: bool,
  nightsOnly: bool,
  noSum: bool,
  bestPricePhrase: object,
};

const defaultProps = {
  className: null,
  isMobile: false,
  sumOnly: false,
  nightsOnly: false,
  noSum: false,
  bestPricePhrase: null,
};

function BookingCalculator({
  dates,
  stayData,
  nightFromLabel,
  yourStayLabel,
  yourPriceLabel,
  nightSingleLabel,
  nightPluralLabel,
  className,
  isMobile,
  sumOnly,
  nightsOnly,
  noSum,
  bestPricePhrase,
}) {
  const areDatesAvailable = useMemo(() => {
    // Exclude last date, because it could be unavailable (for check out only)
    const checkDates = dates.slice(0, -1);
    return checkDates.some(({ available }) => available === true);
  }, [dates]);

  const minPrice = useMemo(
    () =>
      areDatesAvailable
        ? Math.min(
            ...dates
              .filter(
                (date) =>
                  Number(date.price) !== 0 &&
                  (date.hasOwnProperty('available') ? date.available : true)
              )
              .map(({ price }) => Number(price))
          )
        : 0,
    [areDatesAvailable, dates]
  );

  const nightLabel =
    stayData.stayNights === 1 ? nightSingleLabel : nightPluralLabel;

  return (
    (!!dates.length || !Object.keys(stayData)) && (
      <div className={cx('root', className)}>
        {(!!dates.length || !Object.keys(stayData)) && sumOnly ? (
          <>
            {stayData.staySum > 0 && !noSum && (
              <>
                <span className={cx('highlighted')}>{yourPriceLabel}</span>
                <span className={cx('price')}>{` ${stayData.staySum}€`}</span>
              </>
            )}
          </>
        ) : (
          <>
            {!!dates.length &&
              !isMobile &&
              areDatesAvailable &&
              !nightsOnly && (
                <div className={cx('from')}>
                  <span className={cx('highlighted')}>{nightFromLabel}</span>
                  <span className={cx('price')}>{` ${minPrice}€`}</span>
                </div>
              )}
            {!!stayData && stayData.staySum > 0 && stayData.stayNights && (
              <div className={cx('total')}>
                {isMobile ? (
                  <>
                    <span className={cx('highlighted')}>
                      {!noSum ? yourPriceLabel : yourStayLabel}
                    </span>
                    {!noSum && (
                      <span className={cx('price')}>
                        {` ${stayData.staySum}€`}
                      </span>
                    )}
                    <div>{`${stayData.stayNights} ${nightLabel}`}</div>
                  </>
                ) : noSum ? (
                  <div className={cx('total-stay-wrapper')}>
                    {bestPricePhrase && (
                      <div className={cx('best-price-phrase')}>
                        <HighlightedText index={bestPricePhrase.index}>
                          {bestPricePhrase.value}
                        </HighlightedText>
                      </div>
                    )}
                    {`${yourStayLabel} ${stayData.stayNights} ${nightLabel}`}
                  </div>
                ) : (
                  `${yourPriceLabel} ${stayData.staySum}€ (${stayData.stayNights} ${nightLabel})`
                )}
              </div>
            )}
          </>
        )}
      </div>
    )
  );
}

BookingCalculator.propTypes = propTypes;
BookingCalculator.defaultProps = defaultProps;

export default BookingCalculator;
