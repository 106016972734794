import React from 'react';
import { bool, object, string, func } from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import RadioButtonsContainer from 'common/components/FormComponents/RadioButtonsContainer';
import Radio from 'common/components/FormComponents/Radio';
import Select from 'common/components/FormComponents/Select';
import InputPassword from 'common/components/FormComponents/InputPassword';
import InformationPanel from 'common/components/InformationPanel';
import { MYACC_LANGUAGE } from 'common/constants';
import { useEffectOnMount } from 'common/hooks';
import gtmPushEvent from 'common/utils/gtmPushEvent';
import AuthFormTitle from 'common/components/AuthFormTitle';

import Checkbox from '~/components/FormComponents/Checkbox';
import useCountryNameKey from '~/hooks/useCountryNameKey';
import usePrioritisedCountries from '~/hooks/usePrioritisedCountries';
import SelectProfession from '~/components/FormComponents/SelectProfession';
import FormButton from '~/components/MyidComponents/FormButton';
import ServiceLinks from '~/components/MyidComponents/ServiceLinks';
import ConsentHandler from '~/components/FormComponents/ConsentHandler';
import InfoMessages from '~/components/InfoMessages';

import FormSignUpComboHandler from './containers/FormSignUpComboHandler';
import styles from './FormSignUpCombo.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  signUpData: object,
  submit: func.isRequired,
  submitSuccess: bool,
  submitError: bool,
  submitErrorMessage: object,
  isSubscribeDisabled: bool.isRequired,
  isMyMCDisabled: bool.isRequired,
  dataConsent: string,
};

const defaultProps = {
  signUpData: null,
  submitSuccess: false,
  submitError: false,
  submitErrorMessage: null,
  dataConsent: null,
};

const gtmSignupStep2Params = {
  event: 'load_account_registration_flow_step2',
};

function FormSignUpCombo({
  signUpData,
  submit,
  submitErrorMessage,
  submitSuccess,
  submitError,
  isSubscribeDisabled,
  isMyMCDisabled,
  dataConsent,
}) {
  const { t } = useTranslation(['auth', 'common']);
  const title = t('create.formTitle.value');
  const titleHighlight = t('create.formTitle.color_position');
  const termsAndConditionsText = t('create.termsAndConditionsText');
  const termsAndConditionsMyMCText = t('create.termsAndConditionsMyMCText');
  const genderLabel = t('create.fieldGenderLabel');
  const passwordLabel = t('create.fieldPasswordLabel');
  const fieldCountryOfResidenceLabel = t('create.fieldCountryOfResidenceLabel');
  const subscribeLabel = t('create.fieldSubscribeLabel');
  const joinLoyaltyLabel = t('create.fieldJoinLoyaltyLabel');
  const messageSuccess = t('create.messageSuccess');
  const messageError = t('messageError');
  const serviceLinks = t('create.serviceLinks');
  const languageLabel = t('create.fieldLanguageLabel');
  const professionLabel = t('common:professionLabel');

  const infoLabels = t('create.messages', {
    returnObjects: true,
  });

  const countryNameKey = useCountryNameKey();
  const prioritisedCountries = usePrioritisedCountries();

  useEffectOnMount(() => {
    gtmPushEvent(gtmSignupStep2Params);
  });

  return (
    <FormSignUpComboHandler signUpData={signUpData}>
      {({
        validationSchema,
        initialValues,
        genderList,
        captchaStatus,
        captchaReset,
        CaptchaWidget,
      }) => (
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values, formik) => {
            submit({ values, formik, captchaStatus, captchaReset });
          }}
        >
          {({ values, errors, isSubmitting, dirty }) => (
            <Form className={cx('form')} autoComplete="off" noValidate>
              <AuthFormTitle title={title} index={titleHighlight} />
              <div className={cx('form-content')}>
                <Field
                  name="password"
                  theme="block-theme"
                  component={InputPassword}
                  placeholder={passwordLabel}
                  required
                  maxLength={128}
                  className={cx('input')}
                  autoComplete="off"
                />

                <div className={cx('checkboxes-wrapper')}>
                  {!isSubscribeDisabled && (
                    <>
                      <Field
                        name="subscribe"
                        label={subscribeLabel}
                        component={Checkbox}
                        className={cx('checkbox')}
                        textClassName={cx('checkbox-text')}
                        classNameLabel={cx('checkbox-label')}
                      />
                      <Field
                        name="consent"
                        component={ConsentHandler}
                        dataConsent={dataConsent}
                        handledFieldName={'subscribe'}
                      />
                    </>
                  )}

                  {!isMyMCDisabled && (
                    <Field
                      name="mymc"
                      label={joinLoyaltyLabel}
                      component={Checkbox}
                      className={cx('checkbox')}
                      textClassName={cx('checkbox-text', 'highlighted')}
                      classNameLabel={cx('checkbox-label')}
                    />
                  )}
                </div>

                {values.mymc && (
                  <>
                    <RadioButtonsContainer
                      className={cx('radios-wrapper')}
                      label={genderLabel}
                      required
                    >
                      {genderList.map(({ value, label }) => (
                        <Field
                          key={value}
                          name="gender"
                          component={Radio}
                          id={value}
                          label={label}
                          smallStyledView
                        />
                      ))}
                    </RadioButtonsContainer>

                    <Field
                      name={'countryOfResidence'}
                      component={Select}
                      theme="block-theme"
                      options={prioritisedCountries}
                      placeholder={fieldCountryOfResidenceLabel}
                      customKeys={['id', countryNameKey]}
                      required
                    />

                    <Field
                      name={'language'}
                      component={Select}
                      theme="block-theme"
                      options={MYACC_LANGUAGE}
                      customKeys={['langISO3', 'title']}
                      placeholder={languageLabel}
                      classNameWrapper={cx('language-select')}
                    />

                    <Field
                      name="occupation"
                      theme="block-theme"
                      component={SelectProfession}
                      placeholder={professionLabel}
                      required
                    />
                  </>
                )}

                {submitSuccess && (
                  <InformationPanel withIcon successMode>
                    {messageSuccess}
                  </InformationPanel>
                )}
                {submitError && (
                  <InformationPanel withIcon errorMode>
                    {messageError}
                  </InformationPanel>
                )}
                {submitErrorMessage && (
                  <InformationPanel isComponentChildren withIcon errorMode>
                    <InfoMessages
                      isTextMode
                      mode={'error'}
                      messages={submitErrorMessage}
                      labels={infoLabels}
                    />
                  </InformationPanel>
                )}

                <div
                  className={cx('text')}
                  dangerouslySetInnerHTML={{
                    __html: values.mymc
                      ? termsAndConditionsMyMCText
                      : termsAndConditionsText,
                  }}
                />

                <FormButton
                  isSubmitting={isSubmitting || captchaStatus.loading}
                  disabled={
                    !dirty ||
                    Object.entries(errors).length > 0 ||
                    isSubmitting ||
                    submitError ||
                    submitSuccess ||
                    !captchaStatus.solution ||
                    captchaStatus.loading
                  }
                  buttonLabel={title}
                />

                <CaptchaWidget className={cx('captcha')} />
                <ServiceLinks data={serviceLinks} />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </FormSignUpComboHandler>
  );
}

FormSignUpCombo.propTypes = propTypes;
FormSignUpCombo.defaultProps = defaultProps;

export default FormSignUpCombo;
