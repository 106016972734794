// /fr/restaurant
// /ru/restaurant-monaco
// /de/restaurant/
// /it/restaurant/ddd
// /fr/ristoranti
// /fr/hotel-monaco/ // !!! should not match
// /fr/hotel-monaco/rest // !!! should not match
// https://b2c-sbm-dev.forge.smile.fr/fr/hotel-monaco/hotel-paris-monte-carlo/restaurants
// /fr/hotel-monaco/monte-carlo-bay-hotel-resort/restaurant
// /fr/hotel-monaco/monte-carlo-bay-hotel-resort/restaurant/sss
// /fr/hotel-monaco/monte-carlo-bay-hotel-resort/ristoranti
// /fr/hotel-monaco/monte-carlo-bay-hotel-resort/restaurants
// /fr/hotel-monaco/monte-carlo-bay-hotel-resort/ristoranti
// /fr/hotel-monaco/monte-carlo-beach/restaurants
// /fr/hotel-monaco/monte-carlo-beach/ristoranti
// /fr/hotel-monaco/hotel-hermitage-monte-carlo/restaurants
// /fr/hotel-monaco/hotel-hermitage-monte-carlo/ristoranti
// /fr/casino-monaco/casino-monte-carlo/restaurant
// /fr/casino-monaco/casino-monte-carlo/ristoranti

export default function (url) {
  return /(fr|it|en|de|ru|zh-hans)\/(restaurant|ristorant|hotel-monaco\/hotel-paris-monte-carlo\/restaurant|hotel-monaco\/hotel-paris-monte-carlo\/ristorant|hotel-monaco\/monte-carlo-bay-hotel-resort\/restaurant|hotel-monaco\/monte-carlo-bay-hotel-resort\/ristorant|hotel-monaco\/monte-carlo-bay-hotel-resort\/restaurants|hotel-monaco\/monte-carlo-beach\/restaurants|hotel-monaco\/monte-carlo-beach\/ristorant|hotel-monaco\/hotel-hermitage-monte-carlo\/restaurants|hotel-monaco\/hotel-hermitage-monte-carlo\/ristorant|casino-monaco\/casino-monte-carlo\/restaurant|casino-monaco\/casino-monte-carlo\/ristorant)[\/\-\w]*/.test(
    url
  );
}
