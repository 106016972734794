import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import { useAuthContext } from '~/context/AuthContext';
import { useMyaccHistory } from '~/hooks';

import styles from './MyidNav.module.scss';

const cx = classNames.bind(styles);

const MyidNav = () => {
  const myaccHistory = useMyaccHistory();
  const { setErrorMsg } = useAuthContext();
  const { pathname } = useLocation();

  const { t } = useTranslation('auth');
  const loginButtonLabel = t('loginButtonLabel');
  const signupButtonLabel = t('signupButtonLabel');

  const onSigninButtonClick = () => {
    setErrorMsg('');
    myaccHistory.push({
      pathname: '/',
      search: myaccHistory.location.search,
    });
  };

  const onSignupButtonClick = () => {
    setErrorMsg('');
    myaccHistory.push({
      pathname: '/create-account',
      search: myaccHistory.location.search,
    });
  };

  return (
    <div className={cx('root')}>
      <button
        disabled={!pathname.includes('/create-account')}
        onClick={onSigninButtonClick}
        className={cx('nav-button', {
          disabled: !pathname.includes('/create-account'),
        })}
      >
        {loginButtonLabel}
      </button>
      <button
        disabled={pathname.includes('/create-account')}
        onClick={onSignupButtonClick}
        className={cx('nav-button', {
          disabled: pathname.includes('/create-account'),
        })}
      >
        {signupButtonLabel}
      </button>
    </div>
  );
};

export default MyidNav;
