import React, { useState } from 'react';
import Head from 'react-helmet';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import useWarnOnBeforeUnload from 'common/hooks/useWarnOnBeforeUnload';

import GTM from '~/components/GTM';
import {
  FORM_CHECK_EMAIL_STATE,
  FORM_PASSWORD_STATE,
  FORM_MESSAGE_STATE,
} from '~/constants';
import FormCheckEmail from '~/scenes/FormCheckEmail';
import FormSignUpCombo from '~/scenes/FormSignUpCombo';
import FormMessage from '~/components/MyidComponents/FormMessage';
import { useLocalisationContext } from '~/context/LocalisationContext';

import CreateAccountHandler from './containers/CreateAccountHandler';
import styles from './CreateAccount.module.scss';

const cx = classNames.bind(styles);

function CreateAccount() {
  const [isDiabledWarnBeforeUnload, setDiabledWarnBeforeUnload] =
    useState(false);

  useWarnOnBeforeUnload(isDiabledWarnBeforeUnload);

  const { language } = useLocalisationContext();

  const { t } = useTranslation('auth');
  const pageTitle = t('create.pageTitle');
  const infoLabels = t('create.messages', { returnObjects: true });

  const didomiCookiesLabelMapping = {
    en: 'Cookies settings',
    fr: 'Paramètres cookies',
    it: 'Impostazioni Cookie',
  };

  const handleDidomiCookiesClick = () => {
    if (window.Didomi) {
      window.Didomi.preferences.show();
    }
  };

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <div className={cx('root')}>
        <CreateAccountHandler
          setDiabledWarnBeforeUnload={setDiabledWarnBeforeUnload}
        >
          {({
            formState,
            emailExists,
            signUpData,
            checkEmailHandler,
            signUpComboHandler,
            submitSuccess,
            submitError,
            apiErrors,
            submitErrorMessage,
            formMessages,
            resetMessage,
            isSubscribeDisabled,
            isMyMCDisabled,
            dataConsent,
          }) => (
            <div className={cx('form-wrapper')}>
              {formState === FORM_CHECK_EMAIL_STATE && (
                <FormCheckEmail
                  submit={checkEmailHandler}
                  emailExists={emailExists}
                  submitSuccess={submitSuccess}
                  submitError={submitError}
                  apiErrors={apiErrors}
                  resetMessage={resetMessage}
                />
              )}
              {formState === FORM_PASSWORD_STATE && (
                <FormSignUpCombo
                  signUpData={signUpData}
                  submit={signUpComboHandler}
                  submitSuccess={submitSuccess}
                  submitError={submitError}
                  submitErrorMessage={submitErrorMessage}
                  isSubscribeDisabled={isSubscribeDisabled}
                  isMyMCDisabled={isMyMCDisabled}
                  dataConsent={dataConsent}
                />
              )}
              {formState === FORM_MESSAGE_STATE && (
                <FormMessage messages={formMessages} labels={infoLabels} />
              )}
              <button
                type="button"
                onClick={handleDidomiCookiesClick}
                className={cx('button-link')}
              >
                {didomiCookiesLabelMapping[language]}
              </button>
            </div>
          )}
        </CreateAccountHandler>
      </div>
      <GTM />
    </>
  );
}

export default CreateAccount;
