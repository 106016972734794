import React from 'react';
import { func } from 'prop-types';

import ContentLoader from 'common/components/ContentLoader';

import FullscreenContainer from '~/components/FullscreenContainer';

const propTypes = {
  loader: func,
};

const defaultProps = {
  loader: ContentLoader,
};

function PageLoader({ loader: Loader }) {
  return (
    <FullscreenContainer>
      <Loader />
    </FullscreenContainer>
  );
}

PageLoader.propTypes = propTypes;
PageLoader.defaultProps = defaultProps;

export default PageLoader;
