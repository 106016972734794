import React from 'react';

import FullscreenContainer, {
  FullscreenContentWrapper,
} from '~/components/FullscreenContainer';
import Routes from '~/components/Routes';
import Header from '~/scenes/Header';
import MainGrid from '~/scenes/MyAccLayout/components/MainGrid';
import MyIDTopBanner from '~/components/MyIDTopBanner';
import MyidNav from '~/components/MyidComponents/MyidNav';
import { MYID_ROUTES } from '~/constants';
import { useMyaccHistory } from '~/hooks';
import { UserContextProvider } from '~/context/UserContext';

const MyIDLayout = () => {
  const {
    location: { pathname },
  } = useMyaccHistory();

  const currentRoute = pathname.split('/')[2];

  return (
    <>
      {currentRoute === 'wishlist' ? (
        <UserContextProvider>
          <Header />
          <MainGrid>
            <MainGrid.Top>
              <Routes routesList={MYID_ROUTES} />
            </MainGrid.Top>
          </MainGrid>
        </UserContextProvider>
      ) : (
        <FullscreenContainer>
          <Header />
          <MyIDTopBanner />
          <FullscreenContentWrapper>
            <MyidNav />
            <Routes routesList={MYID_ROUTES} />
          </FullscreenContentWrapper>
        </FullscreenContainer>
      )}
    </>
  );
};

export default MyIDLayout;
