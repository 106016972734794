import React, { createContext, useContext, useState, useEffect } from 'react';
import moment from 'moment';
import { set } from 'lodash';

export const ADULTS_INITIAL = 2;
export const ADULTS_MAX = 7;
export const ADULTS_MIN = 1;
export const CHILDREN_INITIAL = 0;
export const CHILDREN_MAX = 3;
export const CHILDREN_MIN = 0;
const CHILDREN_AGE_INITIAL = 7;
const CHILDREN_AGE_MAX = 11;
const CHILDREN_AGE_MIN = 3;

const PersonsContext = createContext();
const usePersonsContext = () => useContext(PersonsContext);
const PersonsContextConsumer = PersonsContext.Consumer;

const PersonsContextProvider = ({ children }) => {
  const [adultsAmount, setAdultsAmount] = useState(ADULTS_INITIAL);
  const [childrenAmount, setChildrenAmount] = useState(CHILDREN_INITIAL);
  const [childrenAges, setChildrenAgesState] = useState([]);

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      const storageInitialAdultsCount =
        localStorage.getItem('initialAdultsCount');
      const storageInitialChildrenCount = localStorage.getItem(
        'initialChildrenCount'
      );
      const storageLastTimeFilterUpdated = localStorage.getItem(
        'lastTimeFilterUpdated'
      );

      if (
        (storageInitialAdultsCount || storageInitialChildrenCount) &&
        storageLastTimeFilterUpdated &&
        moment().diff(moment(Number(storageLastTimeFilterUpdated)), 'days') < 30
      ) {
        if (storageInitialAdultsCount) {
          setAdultsAmount(Number(storageInitialAdultsCount));
        }
        if (storageInitialChildrenCount) {
          setChildrenAmount(Number(storageInitialChildrenCount));
          setChildrenAgesState(
            new Array(Number(storageInitialChildrenCount)).fill(
              CHILDREN_AGE_INITIAL
            )
          );
        }
      }
    }
  }, []);

  const personsAmount = adultsAmount + childrenAmount;
  const isPossibleToAddAdult = adultsAmount < ADULTS_MAX;
  const isPossibleToAddChild = childrenAmount < CHILDREN_MAX;

  const updateAdults = (amount = ADULTS_INITIAL) => {
    setAdultsAmount(amount);
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('initialAdultsCount', amount);
      localStorage.setItem('lastTimeFilterUpdated', moment().valueOf());
    }
  };

  const updateChildren = (amount = CHILDREN_INITIAL) => {
    if (childrenAges.length < amount) {
      setChildrenAgesState((prevState) => [
        ...prevState,
        ...new Array(amount - prevState.length).fill(CHILDREN_AGE_INITIAL),
      ]);
    } else {
      setChildrenAgesState((prevState) => prevState.slice(0, amount));
    }

    setChildrenAmount(amount);

    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('initialChildrenCount', amount);
      localStorage.setItem('lastTimeFilterUpdated', moment().valueOf());
    }
  };

  const addAdults = () => {
    if (adultsAmount < ADULTS_MAX) {
      updateAdults(adultsAmount + 1);
    }
  };

  const removeAdults = () => {
    if (adultsAmount > ADULTS_MIN) {
      updateAdults(adultsAmount - 1);
    }
  };

  const setChildrenAges = (index, value) => {
    setChildrenAgesState((prevState) => {
      const newState = [...prevState];
      newState[index] = value;
      return newState;
    });
  };

  const addChildren = () => {
    if (childrenAmount < CHILDREN_MAX) {
      updateChildren(childrenAmount + 1);
    }
  };

  const removeChildren = () => {
    if (childrenAmount > CHILDREN_MIN) {
      updateChildren(childrenAmount - 1);
    }
  };

  const resetStore = () => {
    updateAdults();
    updateChildren();
    setChildrenAgesState([]);
  };

  return (
    <PersonsContext.Provider
      value={{
        adultsAmount,
        childrenAmount,
        childrenAges,
        setChildrenAges,
        defaultChildrenAges: CHILDREN_AGE_INITIAL,
        maxChildrenAge: CHILDREN_AGE_MAX,
        minChildrenAge: CHILDREN_AGE_MIN,
        personsAmount,
        isPossibleToAddAdult,
        isPossibleToAddChild,
        updateAdults,
        updateChildren,
        addAdults,
        removeAdults,
        addChildren,
        removeChildren,
        resetStore,
      }}
    >
      {children}
    </PersonsContext.Provider>
  );
};

export { PersonsContextConsumer, PersonsContextProvider, usePersonsContext };

export default PersonsContext;
